import classNames from "classnames"

export default function Falcon({ className }) {
  const classes = ["fill-current", className]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(classes)}
      viewBox="0 0 14.758 14.758">
      <path d="M7.408 14.287C3.625 14.287.53 11.192.53 7.408.53 3.625 3.625.53 7.41.53c3.783 0 6.878 3.096 6.878 6.88 0 3.783-3.095 6.878-6.879 6.878zm0-13.229c-3.492 0-6.35 2.858-6.35 6.35 0 3.493 2.858 6.35 6.35 6.35 3.493 0 6.35-2.857 6.35-6.35 0-3.492-2.857-6.35-6.35-6.35z" />
      <path d="M10.53 7.302c.16-.185.794-.132.794-.132.37 0 .397-.264.318-.45-.08-.185-.318-.582-.741-.952-.503-.45-1.085-.794-1.508-1.032-.45-.212-1.059-.53-1.561-.767-.477-.212-1.27-.556-1.72-.688-.45-.159-.53-.185-.582-.185-.053 0-.08.026-.053.053.08.052.873.714 1.164.952.291.238.582.556.794.767.159.186.317.344.344.397 0 0 0 .027-.027.053-.158.08-.688.397-.688.397-.132.08-.793-.132-1.296-.265-.503-.158-1.773-.608-1.958-.635-.159-.053-.291-.079-.503.212-.211.291-.158.423-.026.53.106.079.423.29.926.608.503.317 1.032.688 1.296.873.133.08.424.317.45.45v.661c0 .08 0 .159-.026.185-.027.053-.08.053-.08.053-.873 0-1.667-.159-2.196-.264-.555-.106-1.35-.318-1.402-.318-.053 0-.08.027-.053.053.159.159.926.847 2.223 1.614 2.116 1.27 3.386 1.402 3.862 1.455.344.027.847-.026 1.085-.106.265-.079.45-.264.503-.344.053-.079.08-.185.053-.317-.027-.132-.106-.185-.265-.344-.132-.159-.158-.397-.185-.503a11 11 0 0 1 1.164-.158.997.997 0 0 1 .53.079c.026.026.079.053.079.053s.053.026.053-.027v-.158h.106a.82.82 0 0 1 .185.052c0-.052.026-.105 0-.158 0-.106-.08-.185-.133-.265a.742.742 0 0 0-.185-.503c-.159-.185-.555-.502-.873-.767.053-.026.08-.106.132-.159z" />
    </svg>
  )
}
