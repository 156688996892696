import Falcon from "@/components/common/logos/falcon"
import styles from "@/templates/error/error.module.css"

export function Error() {
  return (
    <main className={styles.wrapper}>
      <Falcon className="logo" />
      <h1>Sidan kan inte visas</h1>
      <p>
        Vi beklagar, men det verkar vara ett problem med systemet just nu.
        Därför kan vi inte visa sidan du försökte nå. Vänligen försök igen
        senare.
      </p>
      <span>Error 500</span>
    </main>
  )
}

export default Error
